import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n';
import router from "./router";

import "bootstrap";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowLeftLong,
  faArrowRotateLeft,
  faBars,
  faChevronRight,
  faSquareCheck,
} from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faArrowLeftLong,
  faArrowRotateLeft,
  faBars,
  faChevronRight,
  faSquareCheck
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

import VueCompositionAPI from '@vue/composition-api';
Vue.use(VueCompositionAPI);

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  render: h => h(App),
  mounted() {
    document.dispatchEvent(new Event("x-app-rendered"));
  }
}).$mount('#app');
