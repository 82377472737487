import Vue from "vue";
import Meta from "vue-meta";
import Router from "vue-router";
import VueI18n from "@/i18n"

import About from "@/views/About.vue";
import Home from "@/views/Home.vue";

Vue.use(Router);
Vue.use(Meta)

const router = new Router({
  mode: "history",
  base: "/",
  routes: [
    {
      path: "/:locale?",
      name: "Home",
      component: Home,
      props: (route) => ({ locale: route.params.locale || 'en' }),
    },
    {
      path: "/:locale/about-us",
      name: "About",
      component: About,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return {
      x: 0, y: 0
    }
  },
});

router.beforeEach((to, from, next) => {
  if (to.params.locale === from.params.locale) {
    next()
    return
  }

  const { locale } = to.params
  VueI18n.locale = locale

  next()
})

export default router