<template>
  <nav class="navbar bg-white">
    <div class="container">
      <!-- Navbar Brand-->
      <a class="navbar-brand" href="#" @click.prevent="goToStart" v-if="isLogoDisplayed">
        <img src="@/assets/logo.svg" width="150" />
      </a>

      <!-- Emergency Rentals-->
      <div class="emergency-rentals" :class="[ isLogoDisplayed ? 'flex-column' : 'flex-row' ]">
        <small v-html="$t('menu.emergencyRentals')"></small>
        <template v-if="!isLogoDisplayed">&nbsp;</template>
        <a href="tel:+18449012407" class="fw-bold text-decoration-none">
          1 844 901-2407
        </a>
      </div>

      <!-- Navbar Toggler -->
      <button class="collapsed navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar-collapse" aria-controls="navbar-collapse" aria-expanded="false" aria-label="Toggle navigation">
        <span id="nav-icon" class="d-block">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </span>
      </button>

      <!-- Navbar Collapse -->
      <div id="navbar-collapse" class="collapse navbar-collapse">
        <div class="navbar-nav py-4">
          <router-link class="nav-link" :to="{ name: 'About', params: { locale: this.$i18n.locale } }">
            {{ $t('menu.aboutUs') }}
            <font-awesome-icon class="text-primary" :icon="['fa', 'chevron-right']" />
          </router-link>
          <a class="nav-link justify-content-end" href="#" @click.prevent="switchLocale">
            {{ $i18n.locale.includes("en") ? "fr" : "en" }}
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: "Navbar",
  props: [ "isLogoDisplayed" ],
  methods: {
    goToStart() {
      this.$emit('hide-confirmation', false)

      if (this.$route.name !== 'Home') {
        this.$router.push({ name: 'Home', params: { locale: this.$i18n.locale } })
      }
      else {
        this.$emit('change-current-step', 1)
        this.currentStep = 1
      }
    },
    switchLocale() {
      const otherLang = this.$i18n.locale.includes("en") ? "fr" : "en";
      this.$i18n.locale = otherLang;
      this.$router.push({
        params: {
          locale: otherLang
        }
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  border-bottom: 1px solid rgba(60, 60, 59, 0.2);
  position: absolute !important;
  top: 0;
  right: 0;
  min-height: 95px;
  width: 100%;
  z-index: 10;
}

.navbar-toggler {
  border: 0;
}

.navbar-toggler:focus {
  box-shadow: none;
  outline: 0;
}

.navbar-nav .nav-link:not(:last-child) {
  border-bottom: 1px solid $primary;
}

.nav-link {
  color: $primary;
  display: flex;
  justify-content: space-between;
  font-family: "Teko", sans-serif;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
}

.emergency-rentals {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 95px;
  margin: auto;
}

/**
 * Navbar Toggler
 */
#nav-icon {
  width: 30px;
  height: 25px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon span {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: $primary;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#nav-icon span:nth-child(1) {
  top: 0;
}

#nav-icon span:nth-child(2),
#nav-icon span:nth-child(3) {
  top: 10px;
}

#nav-icon span:nth-child(4) {
  top: 20px;
}

.navbar-toggler:not(.collapsed) #nav-icon span:nth-child(1) {
  color:$primary;
  top: 10px;
  width: 0;
  left: 50%;
}

.navbar-toggler:not(.collapsed) #nav-icon span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.navbar-toggler:not(.collapsed) #nav-icon span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.navbar-toggler:not(.collapsed) #nav-icon span:nth-child(4) {
  top: 20px;
  width: 0;
  left: 50%;
}
</style>
