<template>
  <div>
    <!-- Title -->
    <div class="row mb-5" v-if="title">
      <div class="col">
        <h2 v-html="title"></h2>
      </div>
    </div>

    <!-- Paragraph -->
    <div class="row mb-5" v-if="paragraph">
      <div class="col">
        <div v-html="paragraph"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line
  name: "Header",
  props: [ "paragraph", "title" ],
}
</script>

<style lang="scss" scoped>

</style>
