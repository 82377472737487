<template>
  <div id="home">
    <div class="d-flex">
      <!-- Left Side -->
      <div class="d-none d-lg-block feature-image-container">
        <template  v-if="currentStep === 1">
        <video  ref="videoPlayer1" :key="currentStep" class="featured-video"  muted="muted" autoplay controls>
          <source :src="getVideoSource()" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </template>
        <template  v-if="currentStep === 2">
          <video :key="currentStep" class="featured-image" autoplay>
            <source :src="getStepImage({ step: 2, ext: 'mp4' })" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </template>

        <template v-if="action === 'contingencyPlan'">
          <img :src="getStepImage({ step: 3, ext: 'gif' })" class="featured-image" v-if="currentStep === 3" />
          <img :src="getStepImage({ step: 7 })" class="featured-image" v-else-if="currentStep === 4" />

          <template v-if="currentStep === 4">
            <div class="form-data" >
              <div>
                <template v-if="data.form.firstName">
                  <div class="row">
                    <div class="col">
                      <div class="form-label">{{ $t(`contingencyPlan.step4.firstName`) }}</div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col">
                      <div class="h3 text-uppercase text-white">
                        <span v-html="data.form.firstName" v-if="data.form.firstName"></span>
                        <span v-if="data.form.firstName && data.form.lastName">&nbsp;</span>
                        <span v-html="data.form.lastName" v-if="data.form.lastName"></span>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="data.form.companyName">
                  <div class="row">
                    <div class="col">
                      <div class="form-label">{{ $t(`contingencyPlan.step4.companyName`) }}</div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col">
                      <div class="h3 text-uppercase text-white" v-html="data.form.companyName"></div>
                    </div>
                  </div>
                </template>
                <template v-if="data.form.email">
                  <div class="row">
                    <div class="col">
                      <div class="form-label">{{ $t(`contingencyPlan.step4.email`) }}</div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col">
                      <div class="h3 text-uppercase text-white" v-html="data.form.email"></div>
                    </div>
                  </div>
                </template>
                <template v-if="data.form.phoneNumber">
                  <div class="row">
                    <div class="col">
                      <div class="form-label">{{ $t(`contingencyPlan.step4.phoneNumber`) }}</div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col">
                      <div class="h3 text-uppercase text-white" v-html="data.form.phoneNumber"></div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
          <img :src="getStepImage({ step: 8 })" class="featured-image" v-else-if="currentStep === 5" />
        </template>

        <template v-if="action === 'rentBoiler'">
          <template v-if="currentStep === 3">
            <video class="featured-image" autoplay loop>
              <source :src="getStepImage({ step: 3, ext: 'mp4' })" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </template>
          <img :src="getStepImage({ step: 4 })" class="featured-image" v-else-if="currentStep === 4" />
          <img :src="getStepImage({ step: 5 })" class="featured-image" v-else-if="currentStep === 5" />
          <img :src="getStepImage({ step: 6 })" class="featured-image" v-else-if="currentStep === 6" />
          <img :src="getStepImage({ step: 7 })" class="featured-image" v-else-if="currentStep === 7" />
          <img :src="getStepImage({ step: 8 })" class="featured-image" v-else-if="currentStep === 8" />

          <template v-if="currentStep === 7">
            <div class="form-data" >
              <div>
                <div class="row mb-2" v-if="data.boilerType">
                  <div class="col">
                    <div class="option" v-html="getLocalizedBoilerType(data.boilerType)"></div>
                  </div>
                </div>
                <div class="row mb-2" v-if="data.boilerCapacity">
                  <div class="col text-end">
                    <div class="option">
                      <span v-html="data.boilerCapacity"></span>
                      <span>&nbsp;</span>
                      <span>BHP</span>
                    </div>
                  </div>
                </div>
                <div class="row mb-5" v-if="data.fuelType">
                  <div class="col">
                    <div class="option" v-html="$t('rentBoiler.step4.options.' + data.fuelType)"></div>
                  </div>
                </div>
                <template v-if="data.form.firstName">
                  <div class="row">
                    <div class="col">
                      <div class="form-label">{{ $t(`rentBoiler.step7.firstName`) }}</div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col">
                      <div class="h3 text-uppercase text-white">
                        <span v-html="data.form.firstName" v-if="data.form.firstName"></span>
                        <span v-if="data.form.firstName && data.form.lastName">&nbsp;</span>
                        <span v-html="data.form.lastName" v-if="data.form.lastName"></span>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-if="data.form.companyName">
                  <div class="row">
                    <div class="col">
                      <div class="form-label">{{ $t(`rentBoiler.step7.companyName`) }}</div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col">
                      <div class="h3 text-uppercase text-white" v-html="data.form.companyName"></div>
                    </div>
                  </div>
                </template>
                <template v-if="data.form.email">
                  <div class="row">
                    <div class="col">
                      <div class="form-label">{{ $t(`rentBoiler.step7.email`) }}</div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col">
                      <div class="h3 text-uppercase text-white" v-html="data.form.email"></div>
                    </div>
                  </div>
                </template>
                <template v-if="data.form.phoneNumber">
                  <div class="row">
                    <div class="col">
                      <div class="form-label">{{ $t(`rentBoiler.step7.phoneNumber`) }}</div>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <div class="col">
                      <div class="h3 text-uppercase text-white" v-html="data.form.phoneNumber"></div>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </template>
        </template>

        <img src="@/assets/images/payment.jpg" class="featured-image" />
      </div>
  
      <!-- Right Side -->
      <div class="right-side-container">
        <Navbar :isLogoDisplayed="currentStep !== 1" @change-current-step="goToStep" @hide-confirmation="hideConfirmation" />
        <div class="container padding-top px-0" v-if="!confirmation">
          <div class="right-side">
            <!-- Step 1 -->
            <div class="my-auto" v-show="currentStep === 1">
              <div class="row mb-5">
                <div class="col">
                  <img src="@/assets/logo.svg" width="250" />
                </div>
              </div>
              <Header :title="$t(`step1.title`)" :paragraph="$t(`step1.paragraph`)" />
              <template v-for="(bulletPoint, bulletPointIndex) in $t(`step1.bulletPoints`)">
                <div class="d-flex align-items-center" :class="{ 'mb-1' : bulletPointIndex < $t(`step1.bulletPoints`).length - 1 }" :key="bulletPointIndex">
                  <img src="@/assets/icons/check.svg" class="me-2" width="25" />
                  <div class="bullet-point" v-html="bulletPoint"></div>
                </div>
              </template>
              <div class="row mt-5">
                <div class="col-sm-8 col-md-6 col-lg-10">
                  <button type="button" ref="next" class="btn btn-primary justify-content-center w-100" @click.prevent="nextStep()">
                    <span v-html="$t(`step1.next`)"></span>
                  </button>
                </div>
              </div>
            </div>

            <!-- Step 2 -->
            <div class="my-auto" v-show="currentStep === 2">
              <Header :title="$t(`step2.title`)" :paragraph="$t(`step2.paragraph`)" />
              <div class="row mb-4">
                <div class="col-sm-8 col-md-6 col-lg-10">
                  <button type="button" class="btn btn-primary w-100" @click.prevent="setAction('rentBoiler')" v-html="$t(`step2.rentABoilerNow`)"></button>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-8 col-md-6 col-lg-10">
                  <button type="button" class="btn btn-primary w-100" @click.prevent="setAction('contingencyPlan')" v-html="$t(`step2.getABoilerContingencyPlanNow`)"></button>
                </div>
              </div>
            </div>

            <!-- Get your boiler contingency plan -->
            <div id="contigency-plan" class="my-auto" v-show="action === 'contingencyPlan'">
              <!-- Step 3 -->
              <div v-show="currentStep === 3">
                <Header :title="$t(`contingencyPlan.step3.title`)" />
                <template v-for="(bulletPoint, bulletPointIndex) in $t(`contingencyPlan.step3.bulletPoints`)">
                  <div class="d-flex align-items-center" :class="{ 'mb-1' : bulletPointIndex < $t(`step1.bulletPoints`).length - 1 }" :key="bulletPointIndex">
                    <img src="@/assets/icons/check.svg" class="me-2" width="25" />
                    <small class="fw-bold text-primary text-uppercase" v-html="bulletPoint"></small>
                  </div>
                </template>
              </div>

              <!-- Step 4 -->
              <div v-show="currentStep === 4">
                <Header :title="$t(`contingencyPlan.step4.title`)" />
                <div class="mb-3">
                  <label for="contingencyPlanFirstName" class="form-label" v-html="$t(`contingencyPlan.step4.firstName`)"></label>
                  <input id="contingencyPlanFirstName" type="text" class="form-control" v-model="data.form.firstName" />
                  <div class="error" v-if="v$.data && v$.data.form && v$.data.form.firstName && v$.data.form.firstName.$errors[0]">
                    {{ v$.data.form.firstName.$errors[0].$message }}
                  </div>
                </div>
                <div class="mb-3">
                  <label for="contingencyPlanLastName" class="form-label" v-html="$t(`contingencyPlan.step4.lastName`)"></label>
                  <input id="contingencyPlanLastName" type="text" class="form-control" v-model="data.form.lastName" />
                  <div class="error" v-if="v$.data && v$.data.form && v$.data.form.lastName && v$.data.form.lastName.$errors[0]">
                    {{ v$.data.form.lastName.$errors[0].$message }}
                  </div>
                </div>
                <div class="mb-3">
                  <label for="contingencyPlanCompanyName" class="form-label" v-html="$t(`contingencyPlan.step4.companyName`)"></label>
                  <input id="contingencyPlanCompanyName" type="text" class="form-control" v-model="data.form.companyName" />
                  <div class="error" v-if="v$.data && v$.data.form && v$.data.form.companyName && v$.data.form.companyName.$errors[0]">
                    {{ v$.data.form.companyName.$errors[0].$message }}
                  </div>
                </div>
                <div class="mb-3">
                  <label for="contingencyPlanEmail" class="form-label" v-html="$t(`contingencyPlan.step4.email`)"></label>
                  <input id="contingencyPlanEmail" type="email" class="form-control" v-model="data.form.email" />
                  <div class="error" v-if="v$.data && v$.data.form && v$.data.form.email && v$.data.form.email.$errors[0]">
                    {{ v$.data.form.email.$errors[0].$message }}
                  </div>
                </div>
                <div class="mb-3">
                  <label for="contingencyPlanEmail2" class="form-label" v-html="$t(`contingencyPlan.step4.confirmEmail`)"></label>
                  <input id="contingencyPlanEmail2" type="email" class="form-control" v-model="data.form.email2" />
                  <div class="error" v-if="v$.data && v$.data.form && v$.data.form.email2 && v$.data.form.email2.$errors[0]">
                    {{ v$.data.form.email2.$errors[0].$message }}
                  </div>
                </div>
                <div class="mb-3">
                  <label for="contingencyPlanPhoneNumber" class="form-label" v-html="$t(`contingencyPlan.step4.phoneNumber`)"></label>
                  <input id="contingencyPlanPhoneNumber" type="text" class="form-control" v-model="data.form.phoneNumber" />
                  <div class="error" v-if="v$.data && v$.data.form && v$.data.form.phoneNumber && v$.data.form.phoneNumber.$errors[0]">
                    {{ v$.data.form.phoneNumber.$errors[0].$message }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Rent a boiler now -->
            <div id="rent-boiler" class="my-auto" v-show="action === 'rentBoiler'">
              <!-- Step 3 -->
              <div  v-show="currentStep === 3">
                <Header :title="$t(`rentBoiler.step3.title`)" />
                <div class="row justify-content-center mb-4">
                  <div class="col-sm-8 col-md-6 col-lg-10">
                    <button type="button" class="btn btn-outline-primary justfiy-content-center w-100" @click.prevent="setBoilerType('heat')" v-html="$t(`rentBoiler.step3.heat`)"></button>
                  </div>
                </div>
                <div class="row justify-content-center mb-4">
                  <div class="col-sm-8 col-md-6 col-lg-10">
                    <button type="button" class="btn btn-outline-primary justfiy-content-center w-100" @click.prevent="setBoilerType('steam')" v-html="$t(`rentBoiler.step3.steam`)"></button>
                  </div>
                </div>
                <div class="row justify-content-center mb-4">
                  <div class="col-sm-8 col-md-6 col-lg-10">
                    <button type="button" class="btn btn-outline-primary justfiy-content-center w-100" @click.prevent="setBoilerType('electric')" v-html="$t(`rentBoiler.step3.electric`)"></button>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-sm-8 col-md-6 col-lg-10">
                    <button type="button" class="btn btn-outline-primary justfiy-content-center w-100" @click.prevent="setBoilerType('glycolAndIndustrialFluids')" v-html="$t(`rentBoiler.step3.glycolAndIndustrialFluids`)"></button>
                  </div>
                </div>
              </div>

              <!-- Step 4 -->
              <div v-show="currentStep === 4">
                <Header :title="$t(`rentBoiler.step4.title`)" />
                <div class="row mb-4">
                  <div class="col-sm-8 col-md-6 col-lg-8">
                    <label for="boiler-capacity" class="fw-bold mb-2 text-primary text-uppercase" v-html="$t(`rentBoiler.step4.boilerCapacity`)"></label>
                    <select id="boiler-capacity" class="form-select" v-model="data.boilerCapacity">
                      <option value="100">100 BHP</option>
                      <option value="200">200 BHP</option>
                      <option value="300">300 BHP</option>
                      <option value="400">400 BHP</option>
                      <option value="500">500 BHP</option>
                      <option value="600">600 BHP</option>
                      <option value="700">700 BHP</option>
                      <option value="800">800 BHP</option>
                      <option value="900">900 BHP</option>
                      <option value="1000">1000 BHP</option>
                      <option value="2000">2000 BHP</option>
                      <option value="3000">3000 BHP</option>
                      <option value="4000">4000 BHP</option>
                    </select>
                    <div class="error" v-if="v$.data && v$.data.boilerCapacity && v$.data.boilerCapacity.$errors[0]">
                      {{ v$.data.boilerCapacity.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-sm-8 col-md-6 col-lg-8">
                    <label for="fuel-type" class="fw-bold mb-2 text-primary text-uppercase" v-html="$t(`rentBoiler.step4.fuelType`)"></label>
                    <select id="fuel-type" class="form-select" v-model="data.fuelType">
                      <option value="biomass">{{ $t('rentBoiler.step4.options.biomass') }}</option>
                      <option value="naturalGas">{{ $t('rentBoiler.step4.options.naturalGas') }}</option>
                      <option value="oil">{{ $t('rentBoiler.step4.options.oil') }}</option>
                      <option value="gasAndOil">{{ $t('rentBoiler.step4.options.gasAndOil') }}</option>
                    </select>
                    <div class="error" v-if="v$.data && v$.data.fuelType && v$.data.fuelType.$errors[0]">
                      {{ v$.data.fuelType.$errors[0].$message }}
                    </div>
                  </div>
                </div>
              </div>

              <!-- Step 5 -->
              <div v-show="currentStep === 5">
                <Header :title="$t(`rentBoiler.step5.title`)" />
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">
                        <input id="selectall" class="form-check-input" type="checkbox" value="" @change="selectAllOptions($event)" />
                      </th>
                      <th scope="col">
                        <label for="selectall" class="h3 text-primary text-uppercase" v-html="$t(`rentBoiler.step5.allOptions`)"></label>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-for="(option, optionIndex) in data.options">
                      <tr :key="optionIndex">
                        <th scope="row">
                          <input :id="'option' + optionIndex" class="form-check-input" type="checkbox" value="" v-model="option.value" />
                        </th>
                        <td>
                          <label :for="'option' + optionIndex" class="form-check-label" v-html="$t('rentBoiler.step5.options.' + option.key)"></label>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>

              <!-- Step 6 -->
              <div v-show="currentStep === 6">
                <Header :title="$t(`rentBoiler.step6.title`)" />
                <div class="row mb-4">
                  <div class="col-sm-8 col-md-6 col-lg-8">
                    <label class="fw-bold mb-2 text-primary text-uppercase" for="start-date" v-html="$t(`rentBoiler.step6.startDate`)"></label>
                    <input id="start-date" class="form-control text-uppercase" type="date" v-model="data.date.start" @change="uncheckIHaveNoIdea" />
                    <div class="error" v-if="v$.data && v$.data.date && v$.data.date.start && v$.data.date.start.$errors[0]">
                      {{ v$.data.date.start.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="row mb-5">
                  <div class="col-sm-8 col-md-6 col-lg-8">
                    <label class="fw-bold mb-2 text-primary text-uppercase" for="end-date" v-html="$t(`rentBoiler.step6.endDate`)"></label>
                    <input id="end-date" class="form-control text-uppercase" type="date" v-model="data.date.end" @change="uncheckIHaveNoIdea" />
                    <div class="error" v-if="v$.data && v$.data.date && v$.data.date.end && v$.data.date.end.$errors[0]">
                      {{ v$.data.date.end.$errors[0].$message }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <input id="dont-know" class="form-check-input" type="checkbox" v-model="data.date.dontKnow" @change="updateDates">
                    <label for="dont-know" class="form-check-label fw-bold ms-2 text-primary text-uppercase" v-html="$t(`rentBoiler.step6.iHaveNoIdea`)"></label>
                  </div>
                </div>
              </div>

              <!-- Step 7 -->
              <div v-show="currentStep === 7">
                <Header :title="$t(`rentBoiler.step7.title`)" />
                <div class="mb-3">
                  <label for="first-name" class="form-label" v-html="$t(`rentBoiler.step7.firstName`)"></label>
                  <input id="first-name" type="text" class="form-control" v-model="data.form.firstName" />
                  <div class="error" v-if="v$.data && v$.data.form && v$.data.form.firstName && v$.data.form.firstName.$errors[0]">
                    {{ v$.data.form.firstName.$errors[0].$message }}
                  </div>
                </div>
                <div class="mb-3">
                  <label for="last-name" class="form-label" v-html="$t(`rentBoiler.step7.lastName`)"></label>
                  <input id="last-name" type="text" class="form-control" v-model="data.form.lastName" />
                  <div class="error" v-if="v$.data && v$.data.form && v$.data.form.lastName && v$.data.form.lastName.$errors[0]">
                    {{ v$.data.form.lastName.$errors[0].$message }}
                  </div>
                </div>
                <div class="mb-3">
                  <label for="company-name" class="form-label" v-html="$t(`rentBoiler.step7.companyName`)"></label>
                  <input id="company-name" type="text" class="form-control" v-model="data.form.companyName" />
                  <div class="error" v-if="v$.data && v$.data.form && v$.data.form.companyName && v$.data.form.companyName.$errors[0]">
                    {{ v$.data.form.companyName.$errors[0].$message }}
                  </div>
                </div>
                <div class="mb-3">
                  <label for="email" class="form-label" v-html="$t(`rentBoiler.step7.email`)"></label>
                  <input id="email" type="email" class="form-control" v-model="data.form.email" />
                  <div class="error" v-if="v$.data && v$.data.form && v$.data.form.email && v$.data.form.email.$errors[0]">
                    {{ v$.data.form.email.$errors[0].$message }}
                  </div>
                </div>
                <div class="mb-3">
                  <label for="email2" class="form-label" v-html="$t(`rentBoiler.step7.confirmEmail`)"></label>
                  <input id="email2" type="email" class="form-control" v-model="data.form.email2" />
                  <div class="error" v-if="v$.data && v$.data.form && v$.data.form.email2 && v$.data.form.email2.$errors[0]">
                    {{ v$.data.form.email2.$errors[0].$message }}
                  </div>
                </div>
                <div class="mb-3">
                  <label for="phone-number" class="form-label" v-html="$t(`rentBoiler.step7.phoneNumber`)"></label>
                  <input id="phone-number" type="text" class="form-control" v-model="data.form.phoneNumber" />
                  <div class="error" v-if="v$.data && v$.data.form && v$.data.form.phoneNumber && v$.data.form.phoneNumber.$errors[0]">
                    {{ v$.data.form.phoneNumber.$errors[0].$message }}
                  </div>
                </div>
              </div>
            </div>

            <!-- Actions -->
            <div class="row justify-content-center mt-4">
              <div class="col">
                <div class="d-flex align-items-center justify-content-between">
                  <a ref="previous" href="#" class="" @click.prevent="previousStep" v-if="(currentStep === 2) || (action === 'rentBoiler' && [3, 4, 5, 6, 7].includes(currentStep)) || (action === 'contingencyPlan' && [3, 4].includes(currentStep))">
                    <font-awesome-icon class="fa-2x me-2 text-primary" :icon="['fa', 'arrow-left-long']" />
                  </a>
                  <button type="button" ref="next" class="btn btn-primary justify-content-center" @click.prevent="nextStep" v-if="(action === 'rentBoiler' && [4, 5, 6, 7].includes(currentStep)) || (action === 'contingencyPlan' && [3, 4].includes(currentStep))">
                      {{ $t(`next`) }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Confirmation -->
        <div class="container padding-top px-0" v-else>
          <div class="right-side">
            <div class="my-auto">
              <Header :title="confirmationMessage" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, requiredIf } from '@/i18n-validators'
import { useVuelidate } from '@vuelidate/core'
import axios from 'axios'

import Header from "@/components/wizard/Header.vue"
import Navbar from "@/components/Navbar.vue"

export default {
  // eslint-disable-next-line
  name: "Home",
  components: {
    Header,
    Navbar
  },
  data() {
    return {
      action: null,
      currentStep: 1,
      index: 1,
      data: {
        boilerType: null,
        boilerCapacity: null,
        fuelType: null,
        options: [
          {
            key: "installation",
            value: false
          },
          {
            key: "boilerMonitoring",
            value: false
          },
          {
            key: "waterTreatment",
            value: false
          },
          {
            key: "maintenancePlan",
            value: false
          }
        ],
        date: {
          start: null,
          end: null,
          dontKnow: false
        },
        form: {
          firstName: null,
          lastName: null,
          companyName: null,
          email: null,
          email2: null,
          phoneNumber: null
        }
      },
      confirmation: false,
      rules: {
        rentBoiler: {
          "step1": {},
          "step2": {},
          "step3": {
            data: {
              boilerType: {
                required: requiredIf(function () {
                  return this.action === "rentBoiler"
                })
              }
            }
          },
          "step4": {
            data: {
              boilerCapacity: { required },
              fuelType: { required }
            }
          },
          "step5": {},
          "step6": {
            data: {
              date: {
                end: {
                  required: requiredIf(function () {
                    return this.data.date.dontKnow !== true
                  })
                },
                start: {
                  required: requiredIf(function () {
                    return this.data.date.dontKnow !== true
                  })
                }
              }
            }
          },
          "step7": {
            data: {
              form: {
                firstName: { required },
                lastName: { required },
                companyName: { required },
                email: { required },
                email2: { required },
                phoneNumber: { required }
              }
            }
          }
        },
        contingencyPlan: {
          "step1": {},
          "step2": {},
          "step3": {},
          "step4": {
            data: {
              form: {
                firstName: { required },
                lastName: { required },
                companyName: { required },
                email: { required },
                email2: { required },
                phoneNumber: { required }
              }
            }
          }
        }
      }
    }
  },
  computed: {
    confirmationMessage() {
      return this.$t(this.action + '.confirmation')
    }
  },
  watch:{
      '$i18n.locale': function() {
        this.updateVideoSource(); 
      }
  }, 
  methods: {
    goToStep(step) {
      this.currentStep = step
    },
    updateVideoSource(){
      const videoPlayer = this.$refs.videoPlayer1;
      videoPlayer.src = this.getVideoSource();
    },
    async nextStep() {
      const isValid = await this.v$.$validate()
      console.log('isValid')
      console.log(isValid)
      if (isValid) {
        this.currentStep++
        this.index++
      }

      // Datalayer
      if (this.currentStep > 2 && this.currentStep >= this.index) {
        const i = this.currentStep - 2
        let eventLabel = this.action === "contingencyPlan" ? 'Boiler Contingency Plan' : "Rent a boiler now"
        eventLabel += " - " + i
        const obj = {
          'event': "event",
          'eventCategory': "form",
          'eventAction': "success",
          'eventLabel': eventLabel,
          'eventValue': ""
        }
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(obj)
        console.log('Pushed object to DataLayer: ')
        console.log(obj)
      }
      console.log(this.action)
      console.log(this.currentStep)

      if (this.action === "rentBoiler" && this.currentStep ===  8) {
        this.confirmation = true
        this.sendEmail()
      }
      else if (this.action === "contingencyPlan" && this.currentStep ===  5) {
        this.confirmation = true
        this.sendEmail()
      }

      // Focus Management:
      if (this.$refs.next) {
        this.$refs.next.blur()
      }
    },
    previousStep() {
      this.currentStep--;
      if (this.$refs.previous) {
        this.$refs.previous.blur()
      }
    },
    selectAllOptions(event) {
      for (let i = 0; i < this.data.options.length; i++) {
        this.data.options[i].value = event.target.checked
      }
    },
    getStepImage(options) {
      let defaultOptions = {
        locale: false,
        ext: 'jpg'
      }

      let mergedOptions = {
        ...defaultOptions,
        ...options
      }

      return mergedOptions.locale ? require(`@/assets/images/step${mergedOptions.step}_${mergedOptions.locale}.${mergedOptions.ext}`) : require(`@/assets/images/step${mergedOptions.step}.${mergedOptions.ext}`)
    },
    updateDates($event) {
      if ($event.target.checked) {
        this.data.date.start = null
        this.data.date.end = null
      }
    },
    uncheckIHaveNoIdea() {
      if (this.data.date.dontKnow) {
        this.data.date.dontKnow = false
      }
    },
    getVideoSource() {
      if(this.$i18n.locale === "en"){
      return require("@/assets/images/about_us_en.mp4");
      } 
      else if (this.$i18n.locale === "fr") {
        return require("@/assets/images/about_us_fr.mp4");
      }
    },
    resumeVideo() {
      const videoPlayer = this.$refs.videoPlayer;
      if (videoPlayer.paused) {
        videoPlayer.play().catch((error) => {
          // Gérer l'erreur de lecture automatique ici
          console.log('Erreur de lecture automatique :', error);
        });
      }
    }, 
    sendEmail() {
      let fields
      if (this.action === 'contingencyPlan') {
        fields = [
          {
            label: "First Name",
            value: this.data.form.firstName
          },
          {
            label: "Last Name",
            value: this.data.form.lastName
          },
          {
            label: "Company Name",
            value: this.data.form.companyName
          },
          {
            label: "Email",
            value: this.data.form.email
          },
          {
            label: "Phone Number",
            value: this.data.form.phoneNumber
          },
        ]
        console.log(fields)
      }
      else if (this.action === 'rentBoiler') {
        fields = [
          {
            label: "First Name",
            value: this.data.form.firstName
          },
          {
            label: "Last Name",
            value: this.data.form.lastName
          },
          {
            label: "Company Name",
            value: this.data.form.companyName
          },
          {
            label: "Email",
            value: this.data.form.email
          },
          {
            label: "Phone Number",
            value: this.data.form.phoneNumber
          },
          {
            label: "Boiler Type",
            value: this.$t('rentBoiler.step3.' + this.data.boilerType, 'en')
          },
          {
            label: "Boiler Capacity",
            value: this.data.boilerCapacity
          },
          {
            label: "Fuel Type",
            value: this.$t('rentBoiler.step4.options.' + this.data.fuelType, 'en')
          }
        ]

        if (this.data.options.filter(option => option.value).length) {
          let selectedOptions = []
          this.data.options
              .forEach((option) => {
                if (option.value) {
                  selectedOptions.push(this.$t('rentBoiler.step5.options.' + option.key, 'en'))
                }
              })

          fields.push({
            label: "Options",
            value: selectedOptions.join(', ')
          })
        }

        if (this.data.date.start) {
          fields.push({
            label: "Start Date",
            value: this.data.date.start,
          })
        }
        if (this.data.date.end) {
          fields.push({
            label: "End Date",
            value: this.data.date.end,
          })
        }
        if (this.data.date.dontKnow) {
          fields.push({
            label: "Dates",
            value: "I Have No Idea Yet",
          })
        }
      }

      const subject = this.action === 'contingencyPlan' ? 'Boiler Contingency Plan' : 'Rent a boiler now'
      const data = {
        action: this.action,
        fields: fields,
        header_text: subject,
        subject: subject,
        to: process.env.VUE_APP_EMAIL_RECIPIENTS
      }

      axios.post(process.env.VUE_APP_BASE_BACKEND_URL + "/send-email", data)
      .then(response => {
        console.log("Success...")
        console.log(response)
        const obj = {
          'event': "event",
          'eventCategory': "form",
          'eventAction': "success",
          'eventLabel': subject,
          'eventValue': "",
        }
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(obj)
        console.log('Pushed object to DataLayer: ')
        console.log(obj)
      })
      .catch(error => {
        console.log("Error...")
        console.log(error)
        const obj = {
          'event': "event",
          'eventCategory': "form",
          'eventAction': "failure",
          'eventLabel': subject,
          'eventValue': "",
        }
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push(obj)
        console.log('Pushed object to DataLayer: ')
        console.log(obj)
      })
    },
    setAction(action) {
      this.action = action
      this.index = 1
      this.nextStep()
    },
    setBoilerType(boilerType) {
      this.data.boilerType = boilerType
      this.nextStep()
    },
    getLocalizedBoilerType(boilerType) {
      return this.$i18n.locale === 'en' ? this.$t('rentBoiler.step3.' + boilerType) + ' Boiler' : 'Chaudière ' + this.$t('rentBoiler.step3.' + boilerType)
    },
    hideConfirmation(status) {
      this.confirmation = status
    },
  },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  validations () {
    console.log(this.action)
    if (this.action) {
      return this.rules[this.action]['step' + this.currentStep]
    }
    else {
      return {}
    }
  },
  metaInfo() {
    return {
      title: this.$t('seo.home.title'),
      description: this.$t('seo.home.description')
    }
  }
};
</script>

<style lang="scss" scoped>
a.modify {
  font-size: 10px;
}

.bullet-point {
  color: $primary;
  font-family: "Teko", sans-serif;
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
}

.error {
  color: red;
  font-size: 12px;
  text-align: left;
}

// Form:
.form-data {
  padding: 0 3rem;
  position: absolute;
  bottom: 50%;
  left: 50%;
  max-width: 400px;
  transform: translateX(-50%);
  width: fit-content;

  &:before {
    content: "";
    background-color: white;
    position: absolute;
    bottom: 0;
    right: 10px;
    height: 15%;
    width: 1px;
  }

  &:after {
    content: "";
    background-color: white;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 40%;
    width: 1px;
  }

  > div:before {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    bottom: 0;
    right: 0;
    height: 50%;
    width: 1px;
  }

  > div:after {
    content: "";
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    bottom: 0;
    left: 10px;
    height: 80%;
    width: 1px;
  }

  .form-label {
    color: #AC0002;
    font-weight: 600;
    margin-bottom: 0;
    text-transform: uppercase;
  }

  .option {
    color: rgba(255, 255, 255, 0.5);
    font-size: $h4-font-size;
    text-transform: uppercase;
  }
}
</style>
