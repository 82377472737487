<template>
  <div id="about">
    <div class="d-flex">
      <!-- Left Side -->
      <div class="d-none d-lg-block position-relative w-100">
        <video ref="videoPlayer" class="featured-video"  muted="muted" autoplay loop @canplaythrough="resumeVideo" controls controlsList="nodownload noremoteplayback" disablepictureinpicture>
          <source :src="getVideoSource()" type="video/mp4">
          Your browser does not support the video tag.
        </video>
      </div>
  
      <!-- Right Side -->
      <div class="right-side-container">
        <Navbar :isLogoDisplayed="true" />
        <div class="container padding-top px-0">
          <div class="right-side">
            <div class="row">
              <div class="col">
                <h2 v-html="$t('aboutUs.firstRow')"></h2>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col">
                <div v-html="$t('aboutUs.secondRow')"></div>
              </div>
            </div>
            
            <div class="row">
              <div class="col">
                <h2 v-html="$t('aboutUs.thirdRow')"></h2>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <div v-html="$t('aboutUs.fourthRow')"></div>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col">
                <router-link class="btn btn-primary" :to="{ name: 'Home', params: { locale: this.$i18n.locale } }" v-html="$t('aboutUs.fifthRow')"></router-link>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <h2 v-html="$t('aboutUs.sixthRow')"></h2>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <div v-html="$t('aboutUs.seventhRow')"></div>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col">
                <router-link class="btn btn-primary" :to="{ name: 'Home', params: { locale: this.$i18n.locale } }" v-html="$t('aboutUs.eighthRow')"></router-link>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <h2 v-html="$t('aboutUs.ninthRow')"></h2>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col">
                <div v-html="$t('aboutUs.tenthRow')"></div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <h2 v-html="$t('aboutUs.eleventhRow')"></h2>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col">
                <div v-html="$t('aboutUs.twelfthRow')"></div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <router-link class="btn btn-primary" :to="{ name: 'Home', params: { locale: this.$i18n.locale } }" v-html="$t('aboutUs.thirteenthRow')"></router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  // eslint-disable-next-line
  name: "About",
  components: {
    Navbar,
  },
  watch:{
      '$i18n.locale': function() {
        this.updateVideoSource(); 
      }
  }, 
  methods: {
    getVideoSource() {
      if(this.$i18n.locale === "en"){
      return require("@/assets/images/about_us_en.mp4");
      } 
      else if (this.$i18n.locale === "fr") {
        return require("@/assets/images/about_us_fr.mp4");
      }
    },
    updateVideoSource(){
      const videoPlayer = this.$refs.videoPlayer;
      videoPlayer.src = this.getVideoSource();
    },
    resumeVideo() {
      const videoPlayer = this.$refs.videoPlayer;
      if (videoPlayer.paused) {
        videoPlayer.play().catch((error) => {
          // Gérer l'erreur de lecture automatique ici
          console.log('Erreur de lecture automatique :', error);
        });
      }
    }
  },
  mounted() {
    const videoPlayer = this.$refs.videoPlayer;
    videoPlayer.play().catch((error) => {
      // Gérer l'erreur de lecture automatique ici
      console.log('Erreur de lecture automatique :', error);
    });
  },
  metaInfo() {
    return {
      title: this.$t('seo.about.title'),
      description: this.$t('seo.about.description'),
    }
  }
};
</script>

<style lang="scss" scoped>

</style>
